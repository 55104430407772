<template>
  <div class="unemployment">
    <div class="totalCount">共有&nbsp;<span style="color:rgb(75,139,239)">{{totalCount}}</span>&nbsp;条数据</div>
    <div class="cont">
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" v-if="dataList.length > 0" offset="10">
        <van-cell v-for="item in dataList" :key="item.id"  @click="getInfo(item.userInfoId, item.id)" class="margin-border">
          <div class="item">
            <van-cell-group :border="false">
              <a :href="'tel:'+item.mobile" @click.stop=""><img src="@/assets/img/phone.png" alt="" class="tel"/></a>
              <van-cell class="itemCont" :title="item.address" :label="item.visitDes" is-link center
                        icon-prefix="widthImg" :icon="item.sex==1?require('@/assets/img/headImgMan.png'):require('@/assets/img/headImgWoman.png')">
                <template #title>
                  <span class="custom-title contTitle"><span>{{item.userName}}</span> / {{item.sex==1?'男':'女'}} / {{item.age}}</span>
                  <span class="custom-title contTitle">
                    上级比对：<span :style="`color:${item.isLowest==1?'rgb(224,75,69)':'rgb(248,170,59)'}`">{{item.isLowest==1?'低保户':'非低保户'}}</span>
                  </span>
                </template>
              </van-cell>
              <van-cell class="title" center title-class="left">
                <template #title>
                  <div>{{desensitization(item.idNumber)}}</div>
                </template>
                <van-button plain type="info" v-if="!!item.id&&item.needRemind" @click.stop="remindHandle(item.id)">确认提醒年审</van-button>
                <van-button plain type="info" v-if="item.buttonType!=3" style="margin-left:10px"
                  @click.stop="handleLabel(item.userInfoId, item.community, item.buttonType)">{{item.buttonType==1?'加上':'去掉'}}低保户标签</van-button>
              </van-cell>
            </van-cell-group>
          </div>
        </van-cell>
      </van-list>
      <van-empty description="没有数据哦"  v-if="dataList.length < 1"/>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { desensitizationIdNumber } from '@/utils/utils'
export default {
  data() {
    return {
      loading: false,
      finished: false,
      page: 0,
      limit: 10,
      totalCount: 0,
      dataList: []
    };
  },
  methods: {
    desensitization (e) {
      return desensitizationIdNumber(e)
    },
    remindHandle (id) {
      this.$dialog.confirm({
        message: '确认提醒年审？'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/social/lowest/confirmRemind'),
          method: 'get',
          params: this.$http.adornParams({
            'id': id,
            'isRemind': 1
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success('操作成功')
            this.page = 0
            this.dataList = []
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    handleLabel (userId, community, buttonType) {
      this.$dialog.confirm({
        message: `确认${buttonType == 1 ? '加上' : '去掉'}低保户标签`
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/wxapp/user/label/${buttonType == 1 ? 'saveLowestLabel' : 'removeLowestLabel'}`),
          method: 'get',
          params: this.$http.adornParams({
            userId,
            community
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.$toast.success('操作成功')
            this.page = 0
            this.finished = false
            this.dataList = []
            this.getDataList()
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    ...mapMutations,
    getInfo (userId, id) {
      this.$router.push({path: '/basicAllowanceInfo', query: {id: id, userId: userId}})
    },
    getDataList () {
      this.page++
      this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
      this.$http({
        url: this.$http.adornUrl('/wxapp/social/lowest/list'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          page: this.page,
          limit: this.limit
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.totalCount = data.page.totalCount
          if (this.dataList.length == data.page.totalCount) {
            this.finished = true
          }
          this.dataList = this.dataList.concat(data.page.list)
          // 加载状态结束
          this.loading = false;
          this.$toast.clear()
        }
        this.$toast.clear()
      },err=> {this.$toast.clear()})
    }
  },
  mounted () {
    this.$toast.loading({
      duration: 0,
      message: '加载中...',
      forbidClick: true,
    });
    this.getDataList()
  }
}
</script>

<style>
.van-grid-item__content {
  padding: 10px  0;
}
.van-search__content {
  border-radius: 30px;
}
</style>
<style lang="scss" scoped>
.tel {
  position:absolute;
  right: 80px;
  top: 20px;
  z-index:999;
  width:50px;
  height:50px;
}
.margin-border {
  padding: 0 !important;
}
.itemCont {
  .van-cell__label {
    width: 420px;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .van-icon__image {
    margin-right: 20px;
  }
}
.van-tag {
  text-align: center;
  display: block;
  width: 190px;
  height: 70px;
  line-height: 70px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}
.contTitle {
  color: #666;
  font-size: 26px;
  width: 420px;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    color: #111;
    font-size: 30px;
  }
}
.widthImg {
  height: 100%;
  img {
    width: 90px;
    height: 90px;
  }
}
.title {
  height: 80px;
  .van-cell__title {
    color: #999;
  }
  .van-cell__value {
    color: #FE5E5E;
    & > .van-button {
      font-size: 24px;
      height: 60px;
    }
    & > .van-button--normal {
      padding: 0 20px;
    }
  }
}
.unemployment {
  min-height: 100vh;
  background-color: #f5f5f5;
  .totalCount {
    color: rgb(47, 47, 47);
    padding: 0 40px;
    background-color: transparent;
  }
  //padding-top: 100px;
  .cont {
    padding: 0 20px;
    background-color: #f5f5f5;
    .van-list > .van-cell {
      margin-bottom: 20px;
      border-radius: 10px;
      padding-top: 0;
      padding-bottom: 0;
      .title {
        margin: 0;
      }
    }
    ul {
      width: 100%;
      height: 100%;
      li {
        background-color: #fff;
        border-radius: 10px;
        margin-top: 20px;
      }
    }
  }
}
.van-icon-ellipsis {
  line-height: 100px;
  vertical-align: middle;
  margin-left: 5px;
}
.left {
  position: absolute;
  left: 30px;
}
</style>
